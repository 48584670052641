import React from "react";
import { SRLWrapper } from "simple-react-lightbox";
import ImageCard from "./ImageCard";

const Gallery = ({ photos = [] }) => {
  return (
    <SRLWrapper>
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
        {photos.map(edge => {
          const imgObj = edge.node.childImageSharp;
          return <ImageCard key={imgObj.id} imgObj={imgObj} />;
        })}
      </div>
    </SRLWrapper>
  );
};

export default Gallery;
