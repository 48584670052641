import React from "react";
import { graphql } from "gatsby";
import { IGatsbyImageData } from "gatsby-plugin-image";
import SimpleReactLightbox from "simple-react-lightbox";

import Gallery from "../../components/Gallery";
import PageHeader from "../../components/PageHeader";
import withLayout from "../../components/hoc/withLayout";

interface PhotographyProps {
  data: {
    allFile: {
      edges: IGatsbyImageData[];
    };
  };
}

const Photography = ({
  data: {
    allFile: { edges: photos },
  },
}: PhotographyProps) => {
  return (
    <SimpleReactLightbox>
      <div className="bg-white pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
        <div className="relative max-w-lg mx-auto divide-y-2 divide-gray-200 lg:max-w-7xl">
          <PageHeader
            header="Photography"
            subheader="Casual shots from either a Sony a6300, Apple iPhone or GoPro Hero. No edits made (yet)."
          />
          <div className="my-12">
            <Gallery photos={photos} />
          </div>
        </div>
      </div>
    </SimpleReactLightbox>
  );
};

export default withLayout(Photography);

export const photographyPageQuery = graphql`
  query PHOTOGRAPHY_PAGE_QUERY {
    allFile(
      filter: {
        extension: { regex: "/(jpg)/" }
        relativeDirectory: { eq: "images/photography" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            id
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
            fixed {
              base64
              src
              srcSet
              originalName
            }
          }
        }
      }
    }
  }
`;
