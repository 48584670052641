import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

// import { PhotoData } from "../models/Photo";

// interface ImageCard {
//   imgObj: PhotoData;
// }

const ImageCard = ({ imgObj }) => {
  return (
    <div className="col-span-1 bg-white">
      <a href={imgObj.gatsbyImageData.images.fallback.src}>
        <GatsbyImage
          alt={imgObj.fixed.originalName}
          image={imgObj.gatsbyImageData}
          className="max-h-96"
        />
      </a>
    </div>
  );
};

export default ImageCard;
